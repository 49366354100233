<template>
  <div>
    <material-stats-card v-bind="config">
      <template #actions>
        <v-icon class="mr-2" small v-text="actionIcon" />
        <div class="text-truncate">{{ actionText }}</div>
      </template>
    </material-stats-card>
  </div>
</template>
<script>
export default {
  props: {
    defaults: Object,
    model: { type: Object, default: () => {} },
  },
  data() {
    return {
      contentHeight: 0,
      idEdit: 0,
      loading: false,
      data: [],
      value: null,
      api: null,
      actionIcon: null,
      actionText: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    config() {
      let res = Object.assign({}, this.model.config);
      res.value = this.value;
      return res;
    },
  },
  methods: {
    async init() {
      if (!this.model.type) return;
      let params = {};
      const user = { id: this.$root.profile.id };
      let resp;
      if (this.model.type == "badgeApi") {
        if (typeof this.model.api === "object") {
          this.api = this.model.api.api;
          //params = JSON.parse(this.model.api.params || "{}");
          //console.log("try eval", this.model.api.params);
          try {
            params = eval("(" + (this.model.api.params || "{}") + ")");
          } catch (error) {}
        }
        if (!this.api) return;
        this.loading = true;
        resp = await this.$axios.get(this.api, { params });
      } else if (this.model.type == "badge") {
      } else return;
      let value;
      if (typeof this.model.value === "object") {
        if (this.model.value.type == "function") {
          let f = this.model.value.func;
          value = eval(f)(resp);
        }
      }
      this.value = value;
      this.loading = false;
    },
  },
};
</script>
